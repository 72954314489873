<template>
  <div class="container w-75 my-5">
    <div v-if="products.length == 0" class="jumbotron text-center">
      <h1 class="jumbotron-heading">
        ¡Sin productos!
        <hr class="my-4">
        <router-link tag="button" class="btn btn-primary" to="/shop">Volver a la página principal</router-link>
      </h1>
    </div>
    <div class="row">
      <itc-product v-for="(product, i) in products" :key="i" :product="product"></itc-product>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Product from '../../components/shop/Product'

export default {
  data(){
    return {
      categoryUrlName: '',
      subCategoryUrlName: '',
      searcher: '',
      products: []
    }
  },
    components: {
    'itc-product': Product
  },
  methods: {
    async getSearchedProducts(){
      var resp;
      if(!this.searcher)
        resp = await axios.get(`/product/category?categoryUrlName=${this.categoryUrlName}&subCategoryUrlName=${this.subCategoryUrlName}&mode=light`)
      else
        resp = await axios.get(`/product/name?searcher=${this.searcher}&mode=light`)
      this.products = resp.data
    },
    refreshDataOnRouteChange(route){
      this.searcher = route.query.searcher
      this.categoryUrlName = route.params.categoryUrlName
      this.subCategoryUrlName = route.params.subCategoryUrlName
    }
  },
    async created(){
      this.refreshDataOnRouteChange(this.$route)
      await this.getSearchedProducts()
  },
  watch: {
    async '$route'(to, from){
      this.refreshDataOnRouteChange(to)
      await this.getSearchedProducts()
    }
  },
}
</script>
